import React from 'react';

const PlatformRatings = ({ platformsRating }) => {
  if (!platformsRating || !Object.values(platformsRating).some(rating => rating !== undefined && rating !== null)) {
    return null;
  }

  return (
    <div className="flex justify-center items-center">
      {platformsRating.imdb !== undefined && platformsRating.imdb !== null && (
        <>
          <img
            src="/imdb_small_icon.png"
            alt="IMDB"
            className="rounded-md max-w-4 max-h-4"
          />
          <span className="text-[0.6rem]">&nbsp;{platformsRating.imdb.toFixed(1)}</span>
          &nbsp;
        </>
      )}
      {platformsRating.kinopoisk !== undefined && platformsRating.kinopoisk !== null && (
        <>
          <img
            src="/kinopoisk_small_icon.png"
            alt="Kinopoisk"
            className="rounded-md max-w-4 max-h-4"
          />
          <span className="text-[0.6rem]">&nbsp;{platformsRating.kinopoisk.toFixed(1)}</span>
        </>
      )}
      {platformsRating.appleBooks !== undefined && platformsRating.appleBooks !== null && (
        <>
          <img
            src="/applebooks_icon.png"
            alt="Apple Books"
            className="rounded-md max-w-4 max-h-4"
          />
          <span className="text-[0.6rem]">&nbsp;{platformsRating.appleBooks.toFixed(1)}</span>
        </>
      )}
      {platformsRating.wowcontentBooks !== undefined && platformsRating.wowcontentBooks !== null && (
        <>
          <img
            src="/logo192.png"
            alt="WowContent Books"
            className="rounded-md max-w-4 max-h-4 ml-1"
          />
          <span className="text-[0.6rem]">&nbsp;{platformsRating.wowcontentBooks.toFixed(1)}</span>
        </>
      )}
      {platformsRating.rottenTomatoes !== undefined && platformsRating.rottenTomatoes !== null && (
        <>
          <span className="text-[0.6rem] font-bold ml-1">
            🍅
          </span>
          <span className="text-[0.6rem]">&nbsp;{Math.round(platformsRating.rottenTomatoes)}%</span>
        </>
      )}
    </div>
  );
};

export default PlatformRatings;
