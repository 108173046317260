import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import MyUserContext from './../myUserContext.js';
import PlatformRatings from './PlatformRatings';

const ImageScroller = ({ items }) => {
  const myUser = useContext(MyUserContext);

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  return (
    <div
      className="flex overflow-x-auto whitespace-nowrap items-center py-2" // Tailwind CSS classes
      style={{ scrollbarWidth: "none" }} // Hide scrollbar (optional for some browsers)
    >
      {items.map((item, index) => (
          <div class="inline-block mx-2">
            <Link
              key={index}
              to={item.link}
              reloadDocument
            >
              <img
                src={item.image}
                alt={`Image ${index}`}
                className="h-full w-32 object-contain cursor-pointer"
              />
              <div class="w-32 flex justify-center text-center">
                {
                  item.category === 1 &&
                    <div class="w-32 truncate text-xs">🎥&ensp;{item.name}</div>
                }
                {
                  item.category === 2 &&
                    <div class="w-32 truncate text-xs">🎮&ensp;{item.name}</div>
                }
                {
                  item.category === 3 &&
                    <div class="w-32 truncate text-xs">📖&ensp;{item.name}</div>
                }
              </div>
            </Link>
            <PlatformRatings platformsRating={item.platformsRating} />
          </div>
      ))}
    </div>
  );
};

export default ImageScroller;
