import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from "react-router-dom";
import {localizeKey, localizeArt} from './../localize.js';
import axiosInstance from './../axiosInstance.js';
import MyUserContext from './../myUserContext.js';
import Collapsible from './Collapsible';
import MyChatComponent from './MyChatComponent';
import ShareButton from './ShareButton';
import CopyTextButton from './CopyTextButton';
import TagsInput from './TagsInput';
import { Link } from 'react-router-dom';

// Why eventType is not part of event?
// Why userName and userInfo are separate?
// Why chatInfo and chatState are separate?
// What's in misc?
const CardEventArtChat = ({ event, eventType, art, chatInfo, language, userName, userInfo, misc, chatState }) => {
  const myUser = useContext(MyUserContext);

  // console.log("CardEventArtChat, myUser: " + myUser.myUserInfo + " , myUserId: " + myUser.myUserInfo.myUserId + " , misc.fromUser: " + misc.fromUser + " , misc.fromUser.id " + misc.fromUser.id);
  const artLink = art.wikiArticleLink;
  const hostnameParts = new URL(artLink).hostname.split('.');
  const secondLevelDomain = hostnameParts[1];
  let iconFile = "/wiki_icon.png";
  if (secondLevelDomain === 'steampowered') {
    iconFile = "/steam_icon.png";
  } else if (secondLevelDomain == 'imdb') {
    iconFile = "/imdb_icon.png"
  } else if (secondLevelDomain == 'apple') {
    iconFile = "/applebooks_icon.png"
  }

  const elementRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          /*
          axiosInstance
          .get(`/artEvents/${event.id}`) // TODO: make a separate light-weight endpoint!
          .then((response)=>{
            // Do nothing, ntf is marked as read.
          })
          .catch((error) => {
            console.error("Failed to mark notification as read: " + error.message);
          });
          */
        }
      },
      {
        threshold: 0.1, // Adjust the threshold as needed
      }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);

  const [myEventType, setMyEventType] = useState(art.myEventType);
  const [eventId, setEventId] = useState(event.id);
  const [eventTags, setEventTags] = useState(event.eventTags);
  const [eventChatId, setEventChatId] = useState(event.chatId);
  const [eventChatInfo, setEventChatInfo] = useState(chatInfo);

  // const handleEventTypeMove = (newEventType) => {
  //   axiosInstance
  //   .post(`/artEvents`, { artId: art.id, type: newEventType })
  //   .then((response) => {
  //     setMyEventType(newEventType); // todo: get rid of separate event type
  //     //console.log("response.data.event.id: " + response.data.event.id);
  //     setEventId(response.data.event.id);
  //   })
  //   .catch((error) => {
  //     console.error(error);
  //   });
  // };

  const [removed, setRemoved] = useState(false);
  const handleEventRemove = (eventId) => {
    axiosInstance
    .delete(`/artEvents/${eventId}`)
    .then((response) => {
      //setRemoved(true); // todo: remove
      //setPinned(false); // it's so ugly I cry
      //setMyEventType("none"); // SO UGLY, just use response from the server to update art
      alert("Removed / Удалено");
    })
    .catch((error) => {
      console.error(error);
    })
  }

  const handleEventTag = (eventId, inputTagNames) => {
    axiosInstance
    .post(`/artEvents/tag/${eventId}/tag`, { tags: inputTagNames })
    .then((response) => {
      setEventTags(response.data.event.eventTags);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  // TODO: probably better use event state common for all its fields.
  const [pinned, setPinned] = useState(event.pinned);
  const handleEventPin = (eventId) => {
    axiosInstance
    .post(`/artEvents/pin/${eventId}/pin`)
    .then((response) => {
      setPinned(true);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 406) {
          alert(localizeKey('pinsLimitReached', language));
        } else {
          console.error(error);
        }
      } else {
        console.error(error);
      }
    })
  }
  const handleEventUnpin = (eventId) => {
    axiosInstance
    .delete(`/artEvents/pin/${eventId}/pin`)
    .then((response) => {
      setPinned(false);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  // TODO: switch chat upon selection.
  const [selectedRecommendationUserTag, setSelectedRecommendationUserTag] = useState((eventType === "recommendationIncoming" || eventType === "recommendationOutgoing") ? event.eventTags[0] : "");
  const updatePersonalRecomChatId = (selectedTag) => {
    if (selectedTag.id === selectedRecommendationUserTag.id) {
      return; // no need to request from backend
    }
    axiosInstance
    .get(`/artEvents/${eventId}?recommendationUserId=${selectedTag.creatorUser}`)
    .then((response) => {
      if (response.data.event.chatId === eventChatId) { // something went wrong, same chat returned for different user
        console.error("same private chat returned for different user");
        return;
      }
      setEventChatId(response.data.event.chatId);
      setSelectedRecommendationUserTag(selectedTag);
      setEventChatInfo(response.data.chat);
    })
    .catch((error) => {
      console.error(error);
    })
  }

  // console.log("chatInfo in CardEventArtChat " + chatInfo);

  // console.log("Constructing CardEventArtChat with chat id " + event.chatId + " with chatMsgCount " + chatMsgCount);

  // if (chatMsgCount === null) {
  //   // fetchChatMsgCount();
  // }

  const recomNotFinished = eventType === "recommendation" && art.myEventType !== "like" && art.myEventType !== "finished";
  let outerClassModification = "";
  if (false && removed) { // todo: refactor
    outerClassModification = "pointer-events-none grayscale";
  } else if (recomNotFinished) {
    outerClassModification = "border border-blue-400 rounded-lg shadow outline-1";
  }

  const queryParams = new URLSearchParams(window.location.search);
  const tagsInitFocus = queryParams.get('tagsInitFocus') === 'true';

  return (
    <>
      <div
        ref={elementRef} 
        class={(outerClassModification !== "" ? outerClassModification + " " : "") + "mx-auto max-w-xl"}
      >
            <div class="text-sm md:text-base mt-2 mx-2 md:ml-1">
              <div class="flex justify-between italic items-center">
                <div class="flex">
                  {
                  [...(art.myEventType ? [{eventId: art.myEventId, user: {avatarUrl: myUser.myUserInfo.myUserAvatarUrl}}] : []), ...(art.myFollowees.likeUserEventPairs || [])].map(
                      (userEventID, index) => {
                        return (
                          <div class="w-10 h-10 rounded-full">
                            <Link to={`/artEvents/${userEventID.eventId}`} reloadDocument>
                              <img
                                src={userEventID.user.avatarUrl}
                                style={{'margin-left': '-'+ index*2 +'px'}}
                                class={"w-10 h-10 rounded-full"}
                              />
                            </Link>
                          </div>
                        )
                      }
                    )
                  }

                  {/*
                    (art.myFollowees.likeUserEventPairs) &&
                    localizeKey('othersReaction', language)
                  */}
                </div>



                <div class="flex">
                  { /* pin */
                    (chatState === "shown" && myUser && event.userID === myUser.myUserInfo.myUserId) ? // chatState check is a hack to check if it's single event page
                      <>
                        {
                        myEventType !== "none" &&
                        <>
                          {
                            pinned ?
                              <div class="cursor-pointer text-2xl" onClick={()=>{handleEventUnpin(eventId)}}>
                                📌<span class="-ml-2 text-sm"> &times; </span>
                              </div>
                            :
                              <div class="cursor-pointer text-2xl grayscale" onClick={()=>{handleEventPin(eventId)}}>
                                📌
                              </div>
                          }
                        </>
                        }
                      </>
                    :
                      <>
                        {
                          pinned &&
                          <div class="text-2xl">
                            📌
                          </div>
                        }
                      </>
                  }
                </div>


              </div>
            </div>

        <div class="mx-auto w-auto flex flex-row pb-2 border-b border-gray-400 shadow max-w-xl">
          <div>


            <Link to={"https://wowcontent.lol/arts/"+art.id} >
              <img class="object-cover rounded-t-lg h-auto min-w-32 w-32 md:min-w-40 md:w-40 rounded-none rounded-s-lg" src={art.thumbnailUrl} alt=""/>
            </Link>

          </div>
          <div class="w-full">

          {
            //(eventType !== "none" && event.userID !== myUser.myUserId) &&
            <div class="text-lg ml-2 md:ml-4">
              {
              (eventType !== "recommendation")
              ?
                <div class="flex text-sm text-white items-center mt-1">                
                  {
                  eventType === "like"
                  ?
                    <>
                      <Link to={`https://wowcontent.lol/users/${event.userID}/likes`}>
                        <img class="rounded-full aspect-square max-w-8 max-h-8" src={userInfo.avatarUrl}/>
                      </Link>
                      <div>                  
                          &nbsp;
                          <span class="font-bold">
                            {(myUser && myUser.myUserInfo.myUserId === event.userID) ? (language === "ru" ? "я" : "I") : userInfo.name}
                          </span>
                        <span class="font-bold">
                          &nbsp;{localizeKey('addedToTheirLib', language)}
                        </span>

                        <div class="px-1 hover:underline leading-none">
                          <a class="text-sm text-gray-400" href={"https://wowcontent.lol/" + (eventType === "recommendation" ? "recommendations/" : "artEvents/") + event.id}>
                            {new Date(event.createdTime).toLocaleDateString(language, { month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
                          </a>
                        </div>
                      </div>
                    </>
                  :
                    <div class="font-bold">
                      {
                      eventType === "recommendationIncoming" 
                      ? 
                        (language === "ru" ? "Рекомендовали вам персонально" : "Recommended to you personally")
                      :
                        (language === "ru" ? "Вы рекомендовали персонально" : "You recommended personally")
                      }
                    </div>
                  }
                </div>
              :
                <div class="text-sm font-bold">
                  {language === "ru" ? "Персональная рекомендация" : "Personal recommendation"}
                </div>
              }

              <div class="flex items-center text-white text-base leading-none mt-1">
                <span>{'«' + localizeArt(art, language).name.trim() /*remove this trim eventually*/ + '»'}</span>&#32;
              </div>            
            </div>
          }

            <div class="ml-2 md:ml-4 text-sm">
              {
                eventType === 'recommendation' &&
                (
                  myUser && misc.fromUser.id === myUser.myUserInfo.myUserId ? // myUser && is to allow non-logged-in which is unnecessary
                    <div class="flex items-center">
                      {localizeKey('toWhomRecommended', language)}:&nbsp;
                      <Link class="flex items-center" to={`/users/${misc.toUser.id}/likes`}>
                        <img class="rounded-full max-w-8 max-h-8" src={misc.toUser.avatarUrl}/>
                        &nbsp;
                        <span class="text-blue-500">
                          {misc.toUser.userName}
                        </span>
                      </Link>
                    </div>
                  :
                    <div class="flex items-center">
                      {localizeKey('byWhomRecommended', language)}:&nbsp;
                      <Link class="flex items-center" to={`/users/${misc.fromUser.id}/likes`}>
                        <img class="rounded-full max-w-8 max-h-8" src={misc.fromUser.avatarUrl}/>
                        &nbsp;
                        <span class="text-blue-500">
                          {misc.fromUser.userName}
                        </span>
                      </Link>
                    </div>
                )
              }
            </div>        

          <div class="md:mt-1">
            <TagsInput 
              language={language} 
              initFocus={tagsInitFocus} 
              editable={(myUser && event.userID === myUser.myUserInfo.myUserId && eventType === "like")} 
              initialTags={eventTags ? eventTags.map((eventTag) => { return eventTag.imgUrl !== "" ? eventTag.name + "\\" + eventTag.imgUrl : eventTag.name; }) : []} 
              selectedTag={eventType.includes('recommendation') ? selectedRecommendationUserTag.name + "\\" + selectedRecommendationUserTag.imgUrl : ""} 
              onTagsChange={(updatedTags) => {handleEventTag(eventId, updatedTags)}}
              onSelectionChange={(selectedTagName) => {
                  let index = -1;
                  if (selectedTagName.includes("\\")) {
                    index = event.eventTags.findIndex(t => t.name === selectedTagName.split("\\")[0]);
                  } else {
                    index = event.eventTags.findIndex(t => t.name === selectedTagName);
                  }
                  updatePersonalRecomChatId(event.eventTags[index]);
                }
              }
            />
          </div>

          <div class="mt-4 flex items-center">
            {
            (chatState === "shown" && myUser && event.userID === myUser.myUserInfo.myUserId) && // chatState check is a hack to check if it's single event page
              <div class="ml-4 text-red-600 hover:text-red-900 hover:cursor-pointer" onClick={() => {
                handleEventRemove(eventId);
              }}>
                {localizeKey('removeFromLib', language)}
              </div>  
            }
          </div>

          </div>
        </div>
        {
        (chatState !== "hidden") &&
        <div>
          <Collapsible
            collapsed={chatState === "shown" ? false : /*collapsed*/ true }
            beforeTextImgUrl={selectedRecommendationUserTag.imgUrl}
            buttonText={
              localizeKey(
                eventType === 'recommendationIncoming' || eventType === 'recommendationOutgoing' ? 'privateChat' : 'chat',
                language
              ) + " (" + eventChatInfo.messageCount + " " + localizeKey('messages', language) + ")"
            }
          >
            <MyChatComponent key={eventChatId} chatId={eventChatId} userChatInfo={eventChatInfo} language={language} myUserInfo={myUser ? myUser.myUserInfo : null} chatOwnerInfo={{type:eventType, id: event.id}}/>
          </Collapsible>
        </div>
        }
      </div>
    </>
  );
}

export default CardEventArtChat;